import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll';

function Service() {
  return (
    
    <div className="service_block" id="service_block">
        <div className="section wf-section">
        <div className="service_block_main_heading" data-aos="fade-up" data-aos-delay="100">
            <h1 className="heading-2 text-center">SERVICES</h1>
            <p className="paragraph-4 text-center mx-5">Our Mission is to make study abroad simple and easy for you</p>
        </div>        
        <div className="container-8 w-container">
            <div className="columns-8 w-row service-block-holder">
                <div className="column-9 w-col w-col-4"  data-aos="zoom-in-up"  data-aos-delay="150" >
                    <img src="assets/service1.png" loading="lazy"  alt="" className="image-20 service_block_image" />
                </div>
                <div className="column-10 w-col w-col-4" data-aos="zoom-in-up"  data-aos-delay="150" >
                    <img src="assets/service2.png" loading="lazy"  alt="" className="image-21 service_block_image" />
                </div>
                <div className="column-11 w-col w-col-4" data-aos="zoom-in-up"  data-aos-delay="150" >
                    <img src="assets/service3.png" loading="lazy"  alt="" className="image-22 service_block_image" />
                </div>
            </div>
            <div className="columns-9 w-row service-block-holder">
                <div className="column-9 w-col w-col-4"  data-aos="zoom-in-up"  data-aos-delay="150" >
                    <img src="assets/service4.png" loading="lazy"  alt="" className="image-23 service_block_image" />
                </div>
                <div className="column-12 w-col w-col-4" data-aos="zoom-in-up"  data-aos-delay="150" >
                    <img src="assets/service5.png" loading="lazy"  alt="" className="image-24 service_block_image" />
                </div>
                <div className="column-13 w-col w-col-4" data-aos="zoom-in-up"  data-aos-delay="150" >
                    <img src="assets/service6.png" loading="lazy"  alt="" className="image-25 service_block_image" />
                </div>
            </div>
        </div>
        {/* <a href="#" className="button button-primary w-button" >REQUEST A CALL</a> */}
        <div className="container-6 w-container"><AnchorLink href="#contact-us-block" className="button service_block_button p-position" style={{fontWeight:'700',top:'60px'}} >REQUEST A CALL</AnchorLink></div>
    </div>
    </div>
  )
}

export default Service