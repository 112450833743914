import React from 'react'
import $ from 'jquery';
import BlogForm from '../Form/BlogForm';

const Canada = () => {

    const ToggleText = (e) =>{        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
        if(e.target.id === "next"){                    
            $('.div-1, #next').removeClass('d-block').addClass('d-none');        
            $('.div-2, #back').removeClass('d-none').addClass('d-block'); 
        }
        if(e.target.id === "back"){                        
            $('.div-1, #next').removeClass('d-none').addClass('d-block');        
            $('.div-2,#back').removeClass('d-block').addClass('d-none');        
        }        
    }    
  return (
        <>       
        <div className="col-md-12 col-lg-8 mx-3 mx-md-0 mx-lg-0 common-shadow content-container">
            <div className="name-block" style={{borderLeft:'6px solid #ff0000'}}>
                <h1 className='pl-2 pb-0'>Canada</h1>
                {/* <p className='text-muted pl-2 pt-0'><strong>Language</strong>: English | <strong>Currency:</strong> Dollar</p> */}
            </div>
            <img src="/assets/canada.jpg" className="mt-3" alt="" />            
            <div className="div-1">
                <h4 className='mt-4 pl-2'>Study, Work And Settle In Canada</h4>
                <p className='text-muted'> In the recent times, Students have become more inquisitive and keen to study in Canada as the education system is more practice oriented and research based rather than theoretical and project-based system and the former leading to development and growth of overseas education. Another reason behind the increasing popularity of overseas education is the fact that employers across industries prefer hiring candidates with international qualifications from top institutes across the globe.        
                <br /><br /> Canada has witnessed a tremendous growth in the number of international education aspirants over the years and this is increasing every year. According to a UN Survey, Canada has been rated as one of the most preferred countries in the world to live consecutively for nine years. It is known for its peaceful, friendly, secured and compassionate environment. The country believes in equivalence, diversity, admiration and tolerance for all individuals just as mentioned in the Canadian Charter of Rights and Freedoms and the Canadian Multiculturalism Act.
                <br /><br />Canada is one of the best international education destinations and is also recognized as one with the safest study environment offering high quality of life and education. The reasons for Canada, being the most preferred study destination by international students areas under.</p>
                <h4 className='mt-4 pl-2'>Study, Work And Settle In Canada Benifits</h4>
                <div className="benifit-box ml-3 mt-4">
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Thorough Practical Study Courses.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>More than 10000 Graduate and Undergraduate Programs.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Students are allowed to work for 20 hours per week.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Post Study Work Permit for up to 3 Years.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Low Education and Living Cost as compare to other Countries.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Permanent Residence options after study.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Scholarship available for eligible Candidates.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Warm Welcoming Culture of Canada.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>No Old funds required to show under SDS framework.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>High Visa Success rate.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Multicultural Study and work Environment.</span></p>
                </div>
           </div>
           <div className="div-2 d-none" >
                <h4 className='mt-4 pl-2'>Study, Work And Settle In Canada Benifits</h4>
                <p className='text-muted'>The Student Direct Stream (SDS) is an expedited tudy permit processing program for those who are applying to study in Canada at a post-secondary designated learning institution (DLI). The SDS is available to legal residents who also reside in</p>
                <div className="ml-3 mt-4">
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>China</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>India</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Morocco</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Pakistan</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Philippines</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Senegal</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Vietnam</span></p>                
                </div>
                <h4 className='mt-4 pl-2'>Canada Study Visa : Apply In 6 Simple Steps</h4>
                <h6 className='text-muted'>The applicant must include the following documents to be eligible for SDS processing:</h6>
                <ol>
                    <li>Proof of a valid language test result, completed within 2 years of the date the SDS application was received, showing either of the following:</li>
                    <p>an International English Language Testing System (IELTS) score of 6.0 or higher in each language skill: listening, reading, writing and speaking a Test d'Evaluation de Français (TEF) score that is equivalent to a Canadian Language Benchmark (CLB) score of at least 7 for each ability: at least 310 for speaking, 249 for listening, 207 for reading and 310 for writing</p>
                    <li> Proof of a Guaranteed Investment Certificate (GIC) of CAN$10,000, or more from any bank insured by the Canadian Deposit Insurance Corporation (CDIC) or any bank listed on the IRCC SDS web pageThe GIC must meet the following criteria:</li>
                    <p>When the GIC has been purchased, the bank provides a letter of attestation, the GIC certificate, the Investment Directions Confirmation or the Investment Balance Confirmation to the applicant.<br />
                    The bank holds the funds in an investment account or a student account that is inaccessible for release to the applicant until the applicant’s arrival in Canada.
                    Upon entry to Canada, the bank must validate the client's identity before releasing funds to the study permit holder.<br />
                    The applicant receives an initial disbursement upon identifying themselves, and the remaining funds are disbursed in monthly or bimonthly installments over a period of 10 to 12 months.</p>
                    <li>Proof of full payment of tuition for the applicant’s first year of study This may be in the form of the following:</li>
                    <div className="ml-2">
                        <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>A receipt from the DLI</span></p>
                        <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>An official letter from the DLI confirming payment of tuition fees</span></p>
                        <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>A receipt from a bank showing that tuition fees have been paid to the DLI</span></p>
                    </div>
                    <p>proof that the tuition fee amount has been transferred into a repository account at the DLI to be applied to the tuition bill at a later date</p>
                    <li>Letter of acceptance from a post-secondary DLI</li>
                    <li>Most recent secondary or post-secondary educational transcripts </li>
                    <li>Proof of completion of upfront medical examination  from a panel physician for applicants</li>
                    <p>who have lived or travelled for 6 months in designated countries or territories during the year before coming to Canada whose field of study requires upfront medical examination results
                    </p>
                    <p className='text-muted'><strong>For more information don't hesitate to contact our Experts at</strong></p>
                </ol>
           </div>
           <div className='button-block text-left ml-3 d-flex justify-content-between'>           
                <button className="button form-button mt-2 mb-4 common-shadow back d-none " id="back" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Back</button>
                <button className="button form-button mt-2 mb-4 common-shadow next float-right" id="next" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Next</button>
           </div>           
        </div>
    </>
  )
}

export default Canada
