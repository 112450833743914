import React,{useState} from 'react'
import $ from 'jquery';

const NewZealand = () => {
    const total_div = $('.blog-block').length; 
    const [counter, setCounter] = useState(1); 
    const ToggleText = (e) =>{        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
        if(e.target.id === "next"){                    
            $('.div-1, #next').removeClass('d-block').addClass('d-none');        
            $('.div-2, #back').removeClass('d-none').addClass('d-block'); 
        }
        if(e.target.id === "back"){                        
            $('.div-1, #next').removeClass('d-none').addClass('d-block');        
            $('.div-2,#back').removeClass('d-block').addClass('d-none');        
        }         
    }   
  return (
    <>
        <div className="col-md-8 mx-3 mx-md-0 mx-lg-0 common-shadow content-container">
            <div className="name-block" style={{borderLeft:'6px solid #ff0000'}}>
                <h1 className='pl-2 pb-0'>New Zealand</h1>            
             </div>
            <img src="/assets/new_zealand.jpg" className="mt-3" alt="" />
          <div className="div-1 blog-block">
            <h4 className='mt-4 pl-2'>Study, Work And Settle In New Zealand</h4>
            <p className='text-muted'>When someone says ‘New Zealand’? You immediately think ‘serenity.’ After all, New Zealand has the cleanest lakes in the world! Now take the serenity and the immense opportunities in New Zealand and put them together. Does that sound like a long-term plan to you?  </p>
            <h4 className='mt-4 pl-2'>Benefits of Studying in New Zealand</h4>
            <div className="steps-2 pl-2">
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Comprehensive Practical Study Courses.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Thousands of Graduate and Undergraduate Programs.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Three Years Bachelor’s Degree Acceptable.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Students are allowed to work for 20 hours per week.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Multicultural Study and work Environment</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Post Study Work Permit for up to 3 Years.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>NO GRE/GMAT for Post graduate studies Required.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Permanent Residence options after study.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Scholarship available for eligible Candidates.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Warm Welcoming Culture of New Zealand.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>High Visa Success rate.</span></p>
            </div>
            <h4 className='mt-4 pl-2'>Eligibility & Requirements for Study in New Zealand</h4>
            <p className='text-muted'>Before applying, make sure that the basic requirements are being met for securing admission in the desired institute and programme and to secure New Zealand study visa. Below is a list of required basic documents for New Zealand:</p>
            <h6><strong>For Admission</strong></h6>
            <div className="steps-2 pl-3">
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Academic documents till date</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>IELTS/TOEFL Score (6.0/5.5 for diploma, 6.0 for the Bachelor & 6.5 for Master)</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Passport</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Work Experience (If any)</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>University/College Application form (Offer letter processing time 2 weeks)</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>For Visa Purpose</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Passport or travel document:</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Offer letter from the College or University</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Financial Documents:</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Medical Certificate</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Bank Balance Certificate</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Bank Statement</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>All types of Savings</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Financial Undertaking from Sponsor</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Income Proof</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Net Worth certificate from Charted Accountant (optional)</span></p>
            
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Police Clearance Certificate</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Student Visa application form and the supplementary information form</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Photograph</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Visa Application Fees</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Covering letter</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Educational certificates (with translations if required)</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>English language requirement</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Evidence of Employment</span></p>
            </div>
            </div>
            <div className="div-2 blog-block d-none" >
                <h4 className='mt-4 pl-2'>Cost of Studying & Living in New Zealand</h4>
                <p className='text-muted'>From postgraduate degrees to short-term English language courses, there is a qualification to suit everyone. Tuition fees for international students vary depending on the type of course, where the student wishes to study and for how long. Students studying in New Zealand for more than one year need to have at least NZ$15,000 to support their stay in New Zealand for first year. If the student is willing to study for less than a year, then the student must have at least NZ$1250 for each month of study to contribute to the living expenses.</p>
                <h4 className='mt-4 pl-2'>Academic years</h4>
                <p className='text-muted'>New Zealand offers two intakes under its higher education programs – July and February. Admissions for vocational courses may also be available in March, April and May while pathway programs may have additional dates available. Always check for available intakes in your course of preference.</p>
                <p className='text-muted'><strong>For more information don’t hesitate to contact our Experts at</strong></p>
            </div>
            <div className='button-block text-left d-flex justify-content-between'>           
                <button className="button form-button mt-2 mb-4 common-shadow back d-none " id="back" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Back</button>
                <button className="button form-button mt-2 mb-4 common-shadow next float-right" id="next" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Next</button>
            </div>  
          </div>
          
        
    </>
  )
}

export default NewZealand
