import React from 'react';
import MainBanner from './MainBanner';
import Service from './Service';
import Feature from './Feature';
import Partner from './Partner';
import Cta from './Cta';
import WhatsappButton from './whatsapp/WhatsappButton';

import ContactUs from './contact_us/ContactUs';

function Home() {  
  return (
    <>
        <MainBanner></MainBanner>        
        <Service></Service>
        <Feature></Feature>
        <Partner></Partner>
        <Cta></Cta>
        <ContactUs></ContactUs>           
        <WhatsappButton width="50" ></WhatsappButton>           
    </>    
  
  );
}

export default Home;
