import React from 'react'

const AboutUs = () => {
  return (
    <div className='about-us-block' id="about-us-block">
    <div className='container m-auto'>
      <h1 className='m-auto heading-2 text-center'>ABOUT US</h1>
      <h6 className="paragraph-4 text-center" style={{margin:"0 2px"}}>Get to Know Our IELTS Community and Mission</h6>      
      <div className="row d-flex justify-content-center mt-5 ">
        <div className="col-md-6 about-us-img">
            <img src="assets/about-us.svg" alt="" />
        </div>
        <div className="col-md-6">
            <div className="about-us-content">
                <p className='text-muted mt-4 mx-sm-0'><strong>Enabling Global Education:</strong> CANUS is a higher education partner for students from around the world. We offer a wide range of solutions, including education, immigration, and housing.</p>
                {/* <div className="row ml-2 ml-sm-0">
                    <div className="col-2 col-sm-2 mr-0 pr-0 pl-0">
                        <img src="assets/contract.png" width={80} alt="" />
                    </div>
                    <div className="col-8 col-md-9 col-lg-6 ml-0 pl-lg-0">
                        <h5 className='pb-0 mb-0'>Visa Consultation</h5>
                        <p>Good information of immigration rules higher than anyone</p></div>
                    </div>                    
                <hr /> */}
                {/* <h5 className='company-founder pb-0 mb-0 position-relative'>Jon Doe</h5>
                <p className='text-muted'>Chairnan and Founder</p> */}
                {/* <hr /> */}
            </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default AboutUs
