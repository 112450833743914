import React from 'react';
import style from '../Testimonial/Testimonial.module.css'
import { useOutletContext } from 'react-router-dom';

const Testimonial3 = (props) => {        
  const {index,name,image,role,des} = useOutletContext();  
  return (
    <div>         
    <div className={style.gtco_testimonials} transitionduration={1000}>
        <div key={index} className={style.card + " text-center"}><img className={style.card_img_top} src={image} alt="" />
            <div className="card-body">
            <h5>{name}<br />
                <span>{role}</span>
            </h5>
            <p className="card-text">“{des}”</p>
            </div>
        </div>                         
    </div>
    </div>
  )
}

export default Testimonial3
