import React from 'react'

const BlogForm = (props) => {
  return (
    <div>        
      <form action="" className="immigration-form common-shadow text-center mt-4">
      <h5 className='form-heading py-4 text-left pl-4'>Free Immigration Assessment</h5>
      <input type="text" placeholder="Your Name" className='my-2' />
      <input type="text" placeholder="Your Phone" className='my-2' /><br />
      <input type="text" placeholder="Subject" className='my-2' /><br />
      <input type="hidden" id="type" value={props.country} />
      <select name="visa_type" id="" className='my-2'>
          <option value="Select Visa">Select Visa</option>
          <option value="Business Visa">Business Visa</option>
          <option value="Diplomatic &amp; Offical Visa">SDiplomatic &amp; Offical Visa</option>
          <option value="Family Visa">Family Visa</option>
          <option value="Student Visa">Student Visa</option>
          <option value="Temporary Work Visa">Temporary Work Visa</option>
          <option value="Visitor Visa">Visitor Visa</option>
      </select><br></br>
      <textarea name="message" className='my-2' rows="4" placeholder="Message" required="required"></textarea>
      <div className='text-left ml-3'><button className="button form-button mt-2 mb-4 common-shadow" role="button"><a href="#" style={{fontWeight:'700'}} >Send A Message</a></button></div>
      </form>       
    </div>
  )
}

export default BlogForm