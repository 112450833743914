import React from 'react'

function Video() {
  return (
    <div>
      <h1>Video</h1>
    </div>
  )
}

export default Video
