import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll';

function Cta() {
  return (
    <div>
      <div className="section-5 wf-section cta_block">
        <div className="columns-7 w-row mob-overflow-hidden cta-block">
            <div className="column-6 w-col w-col-9" data-aos="fade-left"  data-aos-delay="100">
                {/* <p><strong className="bold-text bold-text-4">Making your study abroad planning exciting, bright and way more easier.</strong></p> */}
                <p><strong className="bold-text bold-text-4">With CANUS by your side, make the dream of studying abroad a reality!</strong></p>
                <h1>
                    <strong className="bold-text-3 bold-text-5 text-white">Sign up for a free 30-minute consultation<br /></strong>
                </h1>
                <AnchorLink href="#contact-us-block" className="button mt-3" id="button-blue" style={{fontWeight:'700',background:'#001b69',color:'#fff',textTransform:"uppercase",padding:"15px 30px",letterSpacing:'1px',fontSize:"16px"}}>BOOK NOW</AnchorLink>                
            </div>
            <div className="column-8 w-col w-col-3" data-aos="zoom-in" data-aos-delay="100" >
                <img src="/assets/cta.png" loading="lazy" height="350" alt="" className="image-6" />
            </div>
        </div>
    </div>
    </div>
  )
}

export default Cta
