import React from 'react'
import $ from 'jquery';

const Germany = () => {
    const ToggleText = (e) =>{        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
        if(e.target.id === "next"){                    
            $('.div-1, #next').removeClass('d-block').addClass('d-none');        
            $('.div-2, #back').removeClass('d-none').addClass('d-block'); 
        }
        if(e.target.id === "back"){                        
            $('.div-1, #next').removeClass('d-none').addClass('d-block');        
            $('.div-2,#back').removeClass('d-block').addClass('d-none');        
        }        
    }    
  return (
    <div className="col-md-8 mx-3 mx-md-0 mx-lg-0 common-shadow content-container">
        <div className="name-block" style={{borderLeft:'6px solid #ff0000'}}>
            <h1 className='pl-2 pb-0'>Germany</h1>           
        </div>
        <img src="/assets/germany.jpg" className="mt-3" alt="" />            
        <div className="div-1 blog-block">
            <h4 className='mt-4 pl-2'>Germany is a hub for higher education especially for students from STEM backgrounds.</h4>
            <p className='text-muted'>With free world-class degrees and no-tuition fee for local and international students, Germany has become the topmost preferred country for students worldwide. Almost 400,000 International Students are now studying in Germany!</p>
            <p className='text-muted'>India rose from the 4th to the 2nd in ranking under the ‘international students list’ in Germany in 2022. The significant rate of growth of Indians going to Germany is because of how well-settled they feel in the country. So, let’s discuss more about the Germany Study Visa and how the application is processed.</p>
            <h4 className='mt-4 pl-2'>What is a German Student Visa?</h4>
            <p className='text-muted'>To study in Germany, you mandatorily need a German Student Visa. The minimum duration of this visa is about three months and it can last up to 4 years depending on the duration of your course. This visa can be extended under certain conditions. The German Student  visa can be later used to seek Permanent Residence.</p>
            <h6>Upcoming Intakes for Universities in Germany </h6>
            <p className='text-muted'>The general timelines for application submission are as follows:</p>
            <div className="steps-2 pl-3">
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Intake 1: Summer Semester – Submit your application before January 15th</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Intake 2: Winter Semester –  Submit your application before July 15th</span></p>
            </div>
            <h4 className='mt-4 pl-2'>Top 10 Universities in Germany for International Students in 2022</h4>
            <p className='text-muted'>Here’s the top 10 Universities for International Students as per QS rankings in Germany:</p>
            <table className='table table-bordered'>
                <thead>
                    <tr>
                    <th>QS World University Rankings</th>
                    <th>University Name</th>
                    </tr>
                </thead>                    
                <tbody>
                    <tr>
                        <td>61</td>
                        <td>Technical University of Munich</td>
                    </tr>
                    <tr>
                        <td>62</td>
                        <td>Ludwig-Maximilians-Universität München</td>
                    </tr>
                    <tr>
                        <td>64</td>
                        <td>Ruprecht-Karls-Universität Heidelberg</td>
                    </tr>
                    <tr>
                        <td>116</td>
                        <td>KIT, Karlsruhe Institute of Technology</td>
                    </tr>
                    <tr>
                        <td>121</td>
                        <td>Humboldt-Universität zu Berlin</td>
                    </tr>
                    <tr>
                        <td>130</td>
                        <td>Freie Universitaet Berlin</td>
                    </tr>
                    <tr>
                        <td>144</td>
                        <td>RWTH Aachen University</td>
                    </tr>
                    <tr>
                        <td>147</td>
                        <td>Technische Universität Berlin (TU Berlin)</td>
                    </tr>
                    <tr>
                        <td>168</td>
                        <td>Eberhard Karls Universität Tübingen</td>
                    </tr>
                    <tr>
                        <td>186</td>
                        <td>Albert-Ludwigs-Universitaet Freiburg</td>
                    </tr>                    
                </tbody>                    
            </table>                            
            <p className='text-muted'>After your graduate business degree, you can extend residence permit to find a job & settle in Germany.</p>          
            <h6>APPLY NOW</h6>
            <h4 className='mt-4 pl-2'>What Documents are Required for a German Student Visa?</h4>
            <p className='text-muted'>For the visa process to be started, your documents need to be in place. Though the document requirement is too long, with the accurate information, you’ll be good to go.</p>
            <p className='text-muted'>Find the list of documents which are required for your Germany Student Visa Process:</p>
            <h6><strong>Documents for Visa Application</strong></h6>
            <div className="steps-2 pl-3">
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Documents to Approve University Admission</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>National visa application which is duly filled and signed</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Academic Certificates( 10th, 12th and Degrees)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Your valid passport</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Conditional Admission Certificate: “Bedingter Zulassungsbescheid”(in German)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Photocopies of your passport.</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Confirmed Intensive Course: “Intensiv-Sprachkurs”(in German)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Birth certificate</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Proof of Language Course (3 months fee proof)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Marriage certificate (If applicable)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Admission Certificates: “Zulassungsbescheid”(in German)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Birth Certificate(For dependent children)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Health Insurance</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Photographs (Recent)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>English Proficiency Test/German Proficiency Test</span></p>
            </div>
        </div>
        <div className="div-2 d-none blog-block">
            <h4 className='mt-4 pl-2'>Financial Proofs for a German Student Visa - "Finanzierungsnachweis" (in German)</h4>
            <div className="steps-2 pl-3">
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Deposit €10,236 in a German Blocked Bank Account</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Fund Proof for Parents ( Income, Financial Assets)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Recommendation Letter from German Resident "Verpflichtungserklärung"(in German)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Scholarship Certificates</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Bank Guarantee (by Recognized German Bank)</span></p>
            </div>
            <p className='text-muted'><strong>Bonus Tip: A Blocked Account can be useful for a Student Visa</strong></p>
            <h4 className='mt-4 pl-2'>How to Apply for a Germany Student Visa?</h4>
            <p className='text-muted'>Getting a Germany Student Visa isn’t very difficult. Here are the steps you need to follow: </p>
            <div className="steps-2 pl-3">
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Step 1: Visit the nearest German Embassy or Consulate:</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Step 2: Understand the procedures and requirements:</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Step 3: Set up your Visa Appointment:</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Step 4: Make every Visa Application Document Ready:</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Step 5:Get prepared for your Visa Interview</span></p>            
            </div>
        </div>
        <div className='button-block text-left d-flex justify-content-between'>           
            <button className="button form-button mt-2 mb-4 common-shadow back d-none " id="back" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Back</button>
            <button className="button form-button mt-2 mb-4 common-shadow next float-right" id="next" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Next</button>
        </div> 
    </div>
  )
}

export default Germany
