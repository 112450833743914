import React from 'react'
import style from '../Testimonial/Testimonial2.module.css'
import { useOutletContext } from 'react-router-dom';

function Testimonial2() {
  const {index,name,image,role,des} = useOutletContext();  
  return (
    <div>
        <div className="col-md-11">
        <div className="container" id="Testimonial2" style={{maxWidth:900+" px"}}> 
          <div key={index} className={style.card_custom +" card d-flex mx-auto"}>
              <div className={style.card_image}>
                  <img className={style.slider_image + " img-fluid d-flex mx-auto"} src={image} />
              </div>
              <div className={style.card_text}>
                  <div className={style.card_title}>{role}</div>
                  {des}
              </div>
              <div className={style.footer}>
                  <span id={style.name}>{name}<br /></span>
                  <span id={style.position}>CEO of <a href="#" className={style.link_clr}>Google.com</a></span>
              </div>
          </div>                  
        </div>
        </div>                           
    </div>
  )
}

export default Testimonial2