import React from 'react'

const WhatsappButton = (props) => {
  return (
    <>
        <div className="whatsapp-button-wrap">
            <a href="https://wa.me/+919417644786" data-toggle="tooltip" data-placement="top" title="Contact Us On Whatsapp" className='shadow '>
                <img style={{width:props.width+'px'}} src="assets/icons/whatsapp_btn.svg" alt="" />
            </a>
        </div>

    </>
  )
}

export default WhatsappButton
