import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import 'react-slideshow-image/dist/styles.css';
import 'react-multi-carousel/lib/styles.css';
import { Helmet } from "react-helmet";

import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import React,{useLayoutEffect} from 'react';
import MainHeader from './components/MainHeader';
import Home from './components/Home';
import Footer2 from './components/Footer2';
import Testimonial from './components/Route_Page/Testimonial/Testimonial';
import Video from './components/Route_Page/Video';
import Resource from './components/Route_Page/Resource';
import Testimonial3 from './components/Route_Page/Testimonial/Testimonial2';
import Testimonial2 from './components/Route_Page/Testimonial/Testimonial3';
import Canada from './components/Route_Page/Feature/Canada';
import Australia from './components/Route_Page/Feature/Australia';
import Blog from './components/Route_Page/Feature/Blog';
import Uk from './components/Route_Page/Feature/Uk';

import {Routes, Route ,useLocation} from 'react-router-dom';

import './App.css';
import Testimonial1 from './components/Route_Page/Testimonial/Testimonial1';
import NewZealand from './components/Route_Page/Feature/NewZealand';
import Us from './components/Route_Page/Feature/Us';
import Germany from './components/Route_Page/Feature/Germany';
import ContactUs from './components/contact_us/ContactUs';
import AboutUs from './components/AboutUs';
import ExamCalender from './components/Route_Page/ExamCalender/ExamCalender';

function App() {

  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 
  useEffect(() => {
        AOS.init({
          offset: 200,
          duration: 600,
          easing: 'ease-in-sine',
          delay: 100,
          once: true
          // disable: 'mobile'
        });
      }, [])
      
  return (
    <div className="App">
     <Helmet>
        <title>Canus Ielts Institute Barnala</title>
        <link rel="canonical" href="http://example.com" />
        <meta name="description" content="My app description" />
      </Helmet>
    <Wrapper>
      <MainHeader></MainHeader>
      <div className='spacer-block'></div>
        <Routes>        
            <Route exact path="/" element={<Home />}></Route> 
            <Route exact path="/Contact-us" element={<ContactUs />}></Route> 
            <Route exact path="/ielts-exam-dates" element={<ExamCalender />}></Route> 
            <Route path="/Testimonial" element={<Testimonial />}>
              <Route path="Testimonial-1" element={<Testimonial1 />}></Route>                  
              <Route path="Testimonial-2" element={<Testimonial2 />}></Route>                  
              <Route path="Testimonial-3" element={<Testimonial3 />}></Route>                  
            </Route> 
            <Route path="/Video" element={<Video />}></Route> 
            <Route path="/Resource" element={<Resource />}></Route>                  
            <Route path="/About-us" element={<AboutUs />}></Route>                  
            <Route path="/Blog" element={<Blog />}>
              <Route path="canada" element={<Canada />}></Route>                  
              <Route path="australia" element={<Australia />}></Route>
              <Route path="united-kingdom" element={<Uk />}></Route>
              <Route path="new-zealand" element={<NewZealand />}></Route>
              <Route path="united-state" element={<Us />}></Route>
              <Route path="germany" element={<Germany />}></Route>
            </Route>
            
        </Routes>
        {/* <Footer></Footer>     */}
        <Footer2></Footer2>    
    </Wrapper>
    </div>
  );
}

export default App;
