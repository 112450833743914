import React from 'react'

const ExamCalender = () => {
  return (
    <div className="container common-shadow mt-5">
        <div className="row px-3">
            <div className='box'>
                <h1><strong>IELTS Exam Dates 2023</strong></h1>
            </div>
        </div>
    </div>
  )
}

export default ExamCalender