import React from 'react'
import $ from 'jquery';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Typewriter from 'typewriter-effect';


function MainBanner() {
    var main_heading_arr = [
      'Where Dreams come true',
      'International Education Made Easy',
      // 'Achieving Your Global Language Goals With Us',
      // 'Unlock Your Full Potential With IELTS',
      // 'The Key To Your International Success',
      // 'Elevate your language skills with IELTS'
    ]; 
   
    
  return (
    <div className="banner">
        {/* <img src="/assets/banner_bg2.png" style={{position:"absolute",right:"10px",top:"90px"}} alt="" /> */}
        <div className="section-6 wf-section">
          <div className="container">
              <div className="columns row position-relative">
              {/* <img src="assets/plane-path.png" className='position-absolute' id="plane-path-img" loading="lazy" alt="" /> */}
                  <div className="col-md-6 mx-3 mx-sm-0 text-center text-sm-left  " data-aos="fade-right" >
                      <span className='main-heading-sub-text position-relative pl-5'>CANUS IELTS INSTITUTE BARNALA</span>
                      {/* <h1 className="heading mt-0 pt-0" style={{color:'#001b69',textTransform:'uppercase',fontWeight:"700"}} id="main-heading">International Education Made Easy</h1> */}
                      
                      <h1 className="heading mt-0 pt-0" style={{color:'#001b69',textTransform:'uppercase',fontWeight:"700"}}><Typewriter options={{strings: main_heading_arr, autoStart: true, delay: 50, loop: true,}}/></h1>
                      <div className="text-block-2">All-round expertise • Dedicated counsellors • Amazing success rates</div>                     
                      {/* <div className="text-block-2"><h6>At IELTS, we believe in connecting people and cultures through the power of language. With our expertise, you can confidently take your first steps towards achieving success in your studies, career and life.</h6></div> */}
                      <AnchorLink className="button service_block_button mt-5" href="#contact-us-block" style={{fontWeight:'700'}} >REQUEST A CALL</AnchorLink>
                  </div>
                  <div className="col-md-6 banner-image banner-right-image-wrap" data-aos="zoom-in">
                      <img src="assets/banner_bg_girl.png" loading="lazy" alt="" />
                  </div>
              </div>
          </div>
      </div>
     
    </div>
  )
}

export default MainBanner
