import React from 'react';
import { Slide } from 'react-slideshow-image';

function Partner() {    
    const slideImages = [
        {
            url0: 'assets/partner1.png',
            url1: 'assets/partner2.png',
            url2: 'assets/partner3.png',
            url3: 'assets/partner4.png',
            url4: 'assets/partner5.png',
            url5: 'assets/partner6.png',
            url6: 'assets/partner7.png',
            url7: 'assets/partner8.png',                      
        },
        {
            url0: 'assets/partner9.png',
            url1: 'assets/partner10.png',
            url2: 'assets/partner11.png',
            url3: 'assets/partner12.png',
            url4: 'assets/partner13.png',
            url5: 'assets/partner14.png',
            url6: 'assets/partner15.png',
            url7: 'assets/partner16.png', 
        },
        {
            url0: 'assets/partner17.png',
            url1: 'assets/partner18.png',
            url2: 'assets/partner19.png',
            url3: 'assets/partner20.png',
            url4: 'assets/partner21.png',
            url5: 'assets/partner22.png',
            url6: 'assets/partner23.png',
            url7: 'assets/partner24.png', 
        },        
      ];
  return (
    <div className='partner_block'>
        <div className="section-3 wf-section">
        <div className="partner_heading" data-aos="fade-up" data-aos-delay="300">
            <h1 className="heading-7 text-center" style={{color:'#001b69',fontWeight:'bold'}}>500+ PARTNER INSTITUTIONS</h1>
            {/* <p className="paragraph-2 text-center" style={{color:'#001b69'}}>Studying at world-className colleges and universities is now easier!</p>         */}
            <p className="paragraph-2 text-center" style={{color:'#001b69'}}>Search through a list of world-class schools to study abroad and apply for the program you like.</p>        
        </div>
                <Slide duration="2000">                
                {slideImages.map((slideImage, index)=> (
                <div className="slide w-slide" key={index} data-aos="fade-up" data-aos-delay="500" >
                    <div className="container-10 w-container">
                        <div className="slider-column-1 w-row">
                            <div className="w-col w-col-3"><img src={slideImage.url0} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                            <div className="w-col w-col-3"><img src={slideImage.url1} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                            <div className="w-col w-col-3"><img src={slideImage.url2} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                            <div className="w-col w-col-3"><img src={slideImage.url3} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                        </div>
                        <div className="columns-10 slider-column-2 w-row">
                            <div className="w-col w-col-3"><img src={slideImage.url4} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                            <div className="w-col w-col-3"><img src={slideImage.url5} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                            <div className="w-col w-col-3"><img src={slideImage.url6} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                            <div className="w-col w-col-3"><img src={slideImage.url7} loading="lazy" alt="" className="college-box-shadow common-shadow" /></div>
                        </div>
                    </div>
                    <div className=""><br /></div>
                </div>               
                    ))} 
                </Slide>
                
        {/* <div className="container-4 w-container"><a href="#" className="button common-shadow " style={{padding:"17px 30px"}}>VIEW ALL</a></div> */}
        {/* <div className="container-4 w-container"><button className="button service_block_button" role="button"><a href="#" style={{fontWeight:'700'}} >VIEW ALL</a></button></div> */}
    </div>
    </div>
  )
}

export default Partner