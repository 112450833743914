import React from 'react'
import { Link} from 'react-router-dom';

const Footer2 = () => {
    return (
        <>
            <footer className="footer-01 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                            <h2 className="footer-heading">Canus Institute Barnala</h2>
                            {/* <div className="footer-logo-outer-wrap"><Link to="/" className="w-inline-block"><img src="/assets/favicon2.png" loading="lazy" width="65" alt="" className="image-19" /></Link></div> */}
                            <p>We understand that students have different learning styles and schedules. Therefore, we offer flexible learning options, such as online classes, self-paced learning, and one-to-one coaching, to cater to the diverse needs of our students.</p>
                            <ul className="ftco-footer-social p-0">
                                <li className="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="fa fa-twitter"></i></a></li>
                                <li className="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                <li className="ftco-animate"><a href="#" data-toggle="tooltip" data-placement="top" title="Instagram"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>                        
                        <div className="col-md-6 col-lg-3 pl-lg-5 mb-4 mb-md-0">
                            <h2 className="footer-heading">Quick Links</h2>
                            <ul className="list-unstyled">
                                <li><Link to="/" className="py-1 d-block">Home</Link></li>
                                <li><Link to="/Blog/canada" className="py-1 d-block">Study Abroad</Link></li>
                                <li><Link to="/About-us" className="py-1 d-block about-us-btn">About</Link></li>
                                <li><Link to="/Contact-us" className="py-1 d-block about-us-btn">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <h2 className="footer-heading">Have a Questions?</h2>
                            <div className="block-23 mb-5">
                                <ul>
                                    <li><i className="fa fa-map-marker"></i><span className="text"> 1st Floor Shop No, 219, New Bus Stand Rd Barnala, Punjab 148101</span></li>
                                    <li><a href="tel://+919417644786"><i className="fa fa-phone"></i><span className="text">+91 94176 44786</span></a></li>
                                    <li><a href="mailto:canusimmigration786@gmail.com"><i className="fa fa-paper-plane"></i><span className="text">canusimmigration786@gmail.com</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12 text-center">
                            <p className="copyright">
                                Copyright &copy;{new Date().getFullYear()} All rights reserved by Canus IELTS Insitute Barnala
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer2