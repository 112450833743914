import React from 'react'
import $ from 'jquery';

const Us = () => {
    const ToggleText = (e) =>{        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
        if(e.target.id === "next"){                    
            $('.div-1, #next').removeClass('d-block').addClass('d-none');        
            $('.div-2, #back').removeClass('d-none').addClass('d-block'); 
        }
        if(e.target.id === "back"){                        
            $('.div-1, #next').removeClass('d-none').addClass('d-block');        
            $('.div-2,#back').removeClass('d-block').addClass('d-none');        
        }        
    }    
  return (
  
    <div className="col-lg-8 col-sm-12 mx-3 mx-md-0 mx-lg-0 common-shadow content-container">
        <div className="name-block" style={{borderLeft:'6px solid #ff0000'}}>
            <h1 className='pl-2 pb-0'>United State</h1>                
        </div>
        <img src="/assets/usa.jpg" className="mt-3" alt="" />
        <div className="div-1 blog-block">
        <h4 className='mt-4 pl-2'>Study In US</h4>
        <p className='text-muted'>While USA has a lot of options to choose from in terms of colleges, study programs and courses, the entire system can be quite confusing for students. College - We helps them find the right college under their budget and offers a no-obligation free assessment that helps students easily identify the right college. It then provides individualized counseling with expert counselors who help students understand all aspects of education in <a href="USA.Top">USA.Top</a> Ranked Universities</p>
        <p className='text-muted'>Sixteen universities in the USA are ranked in the world’s top 20 universities. Fifty-two US universities are ranked in the top 100 universities worldwide. Seventy-seven American universities are ranked among the best 200 universities in the world.</p>
        <h4 className='mt-4 pl-2'>Exploring Various Culture</h4>
        <p className='text-muted'>Studying in the USA means exploring different cultures as it is a country of immigrants. Many people from distant land had come over and made it their home.</p>
        <p className='text-muted'>Living in multicultural society improves your confidence, communication, and social skills. You get to know the culture and see world famous places and mingle with the American culture and language.</p>
        <p className='text-muted'>You will come across different regional cultures, history and local traditions. For instance, the West Coast region has many beaches, outdoor activities, the people have a relaxed attitude and you will also find many local international communities.</p>
        <h4 className='mt-4 pl-2'>Ideal Place for Research</h4>
        <p className='text-muted'>If you want to become a research scholar then the US is your perfect choice for higher education. You can get the opportunity to work while you learn. Your research grant will get your tuition fee waived off and provide a stipend, while the intellectual challenges will help you grow professionally.</p>
        <h4 className='mt-4 pl-2'>Ample Job Opportunities</h4>
        <p className='text-muted'>If you have completed a degree from the US then you are entitled to work there for about a year. For working there you have to apply for Optional Practical Training (OPT). Many students get absorbed by the company where they did the OPT. The companies sponsor them for H1-B which is a work visa. Once you are given H1-B, you are allowed to work in the US. These are mostly given in the field of software, engineering, accountancy, teaching or marketing.</p>
        <h4 className='mt-4 pl-2'>Financial Support</h4>
        <p className='text-muted'>Getting financial aid is another major reason for international students to opt for higher education in the US. Many universities offer its grants, loans and stipends for students to meet their daily expenses as well as tuitions in some cases. If you can prove your excellence in academics, then your US education may even turn out to be free. Otherwise, obtaining grants and loans from banks is also easy if you can provide proof of your admission.
        On-campus work programs are also available for students who qualify for a specific kind of job. For instance, if you have a humanities major, you can seek employment at the library.</p>
        </div>
        <div className="div-2 d-none" >
            <h4 className='mt-4 pl-2'>Academic Fexibility</h4>
            <p className='text-muted'>The education system in the US is quite different from your own country. The education system in the US is quite flexible, like it gives you the choice to opt for any subject from any stream. You are then allowed to specialise in that subject without any classes. It is your prerogative to choose class any time of the academic year. Now you can specialize in the area of your choice without having to take any unnecessary classes.</p>
            <p className='text-muted'>You also have the flexibility to choose a class at any time during the academic year. For instance, if you can complete the total number of courses required for you to graduate, you can finish up your master’s degree within a year. You can also spread out your course completion schedule in such a manner as to devote more time for research and complete your course in a couple of years.</p>
            <h4 className='mt-4 pl-2'>Excellent Education</h4>
            <p className='text-muted'>American education is known for its excellence. Getting admission in top US Colleges is not easy. Only the best students can reach the top schools. The US degrees are not just recognized worldwide and accepted, but also widely respected around the world.</p>            
        </div>
        <div className='button-block text-left d-flex justify-content-between'>           
            <button className="button form-button mt-2 mb-4 common-shadow back d-none " id="back" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Back</button>
            <button className="button form-button mt-2 mb-4 common-shadow next float-right" id="next" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Next</button>
        </div>   
    </div>
  
  )
}

export default Us
