import React,{useEffect} from 'react'
import { Link, Outlet ,useLocation} from 'react-router-dom';
import BlogForm from '../Form/BlogForm';
import $ from 'jquery';

const Blog = () => {
    const location = useLocation();
    const curret_active = location.pathname.split("/")[2];
    useEffect(() => {
        $('#'+curret_active).addClass('active-country-list'); 
        $('#'+curret_active).addClass('after-effect');
    }, []);
        
    $(document).on('click','.country-links',function(e){
        e.preventDefault();
        $('.country-links').removeClass('active-country-list');
        $('.country-links').removeClass('after-effect');
        $(this).addClass('active-country-list');        
        $(this).addClass('after-effect');        
    })
  return (
    <div className='container mt-5'>
        <div className="row pb-5">
            <div className="col-lg-4 col-md-12  order-lg-0 order-2 mt-3 mt-md-0 mt-lg-0">               
                <div className='mt-lg-0 mt-sm-5'>
                    <ul className='country-list-block'>
                    <li><Link className='country-links' id="canada" to="/Blog/canada">Canada</Link></li>
                    <li><Link className='country-links' id="germany" to="/Blog/germany">Germany</Link></li>
                    <li><Link className='country-links' id="australia" to="/Blog/australia">Australia</Link></li>
                    <li><Link className='country-links' id="united-state" to="/Blog/united-state">United State</Link></li>
                    <li><Link className='country-links' id="new-zealand" to="/Blog/new-zealand">New Zealand</Link></li>
                    <li><Link className='country-links' id="united-kingdom" to="/Blog/united-kingdom">United Kingdom</Link></li>                                                
                    </ul>
                </div>         
                <BlogForm country={curret_active}></BlogForm>   
            </div>
            <Outlet></Outlet>
        </div>
    </div>
  )
}

export default Blog