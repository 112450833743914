import React,{useState} from 'react'
import $ from 'jquery';

const Uk = () => {
    const total_div = $('.blog-block').length; 
    const [counter, setCounter] = useState(1); 
    
    const ToggleText = (e) =>{       
      if(e.target.id === "next"){                 
          setCounter(counter + 1);           
            // if(counter === (total_div - 1)){
            //   $('#next').attr("disabled", true);
            //   $('#next').css('cursor','no-drop');           
            // }            
            if(counter === 1){              
              $('.div-1').removeClass('d-block').addClass('d-none');        
              $('.div-2, #back , #next').removeClass('d-none').addClass('d-block');   
              $('.div-3').removeClass('d-block').addClass('d-none');                   
              $('.div-4').removeClass('d-block').addClass('d-none');                   
            }else if(counter === 2){            
              $('.div-1').removeClass('d-block').addClass('d-none');        
              $('.div-2').removeClass('d-block').addClass('d-none');        
              $('.div-3,#next, #back').removeClass('d-none').addClass('d-block');                 
              $('.div-4').removeClass('d-block').addClass('d-none');        
            }else if(counter === 3){            
                $('.div-1').removeClass('d-block').addClass('d-none');        
                $('.div-2').removeClass('d-block').addClass('d-none');        
                $('.div-3,#next').removeClass('d-block').addClass('d-none');          
                $('.div-4, #back').removeClass('d-none').addClass('d-block');                 
            }
                
        }
        if(e.target.id === "back"){            
            setCounter(counter - 1);             
            if(counter === 2){              
              $('.div-1, #next').removeClass('d-none').addClass('d-block');   
              $('.div-2').removeClass('d-block').addClass('d-none');        
              $('.div-3, #back').removeClass('d-block').addClass('d-none');
              $('.div-4').removeClass('d-block').addClass('d-none');           
            }else if(counter === 3){
              $('.div-1').removeClass('d-block').addClass('d-none');        
              $('.div-2, #back , #next').removeClass('d-none').addClass('d-block');   
              $('.div-3').removeClass('d-block').addClass('d-none');
              $('.div-4').removeClass('d-block').addClass('d-none');          
            }else if(counter === 4){
                $('.div-1').removeClass('d-block').addClass('d-none');        
                $('.div-2').removeClass('d-block').addClass('d-none');        
                $('.div-3,#next').removeClass('d-block').addClass('d-none');          
                $('.div-4, #back').removeClass('d-none').addClass('d-block');  
            }
            $('#next').attr("disabled", false);
            $('#next').css('cursor','pointer');
           
        }        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
    } 
  return (
    <>
        <div className="col-md-8 mx-3 mx-md-0 mx-lg-0 common-shadow content-container">
        <div className="name-block" style={{borderLeft:'6px solid #ff0000'}}>
            <h1 className='pl-2 pb-0'>United Kingdom</h1>
            {/* <p className='text-muted pl-2 pt-0'><strong>Language</strong>: English | <strong>Currency:</strong> Dollar</p> */}
          </div>
          <img src="/assets/uk.jpg" className="mt-3" alt="" />
          <div className="div-1 blog-block">
            <h4 className='mt-4 pl-2'>UK General Student Visa: Process, Intakes & Top Universities in 2022</h4>
            <p className='text-muted'>With a rich history, the UK is one of the most popular destinations for higher studies. An ideal study abroad destination, studying in the UK will give you access to world-class education in a culturally diverse and stunningly beautiful country. Over 30,000 international students from all over the world are currently studying in the UK.</p>
            <p className='text-muted'>UK, Tier 4 <br />The UK Tier 4 category is for adult students aged 16 or over. You may study at or above the National Qualification Framework level 3 under this category. University and college study programs are included under Tier 4.</p>
            <h4 className='mt-4 pl-2'>Why study in the United Kingdom?</h4>
            <div className="benifit-box ml-3 mt-4">
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>World-class Education System</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Oldest universities and colleges having their roots in the 12th and 13th centuries.</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Academic Excellence.</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Three Years Bachelor’s Degree</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>1-2 Years Master’s Degree</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Part Time Work allowed for up to 20 hours per week</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Post Study Work permit for Two Years</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>High Visa Success Rate</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Only 28 days old funds required to Show.</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Easy Admission and Visa Process.</span></p>                
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Education system in the UK</span></p>
            </div>
            <h4 className='mt-4 pl-2'>The UK is globally recognized for its teaching excellence. Let’s find out why.</h4>
            <p className='text-muted'>The United Kingdom (UK) is reputed for its excellent quality of education, interactive teaching methodology, top ranking universities and great student satisfaction. Universities in the UK consistently rank in the world university rankings such as the QS World Rankings, Times Higher Education Ranking, and Academic Ranking of World Universities. Further, degrees earned from the country are globally recognized and preferred by employers all over the world.</p>
            <p className='text-muted'>Standing at the zenith of research and development, the UK offers students vast scope to build critical and analytical thinking. Here’s why an overview of the education system in the UK.</p>
          </div>
          <div className="div-2 d-none blog-block" >
            <h4 className='mt-4 pl-2'>How does the UK education system work?</h4>
            <p className='text-muted'>The responsibility of education in the UK is delegated to four jurisdictions – England, Northern Ireland, Wales and Scotland. All of these regions have different forms of education systems, qualifications and individual laws/regulations. The major differences can be found in general and secondary education resulting in each region having its own credit frameworks and qualifications.</p>
            <p className='text-muted'>The education system in the UK consists of four stages:</p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Primary education</span></p>                
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Secondary education</span></p>                
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Further education</span></p>                
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Higher education</span></p>       
            <h4 className='mt-4 pl-2'>Qualifications framework</h4>
            <p className='text-muted'>A qualification framework is a principal national reference point that maintains academic standards in the higher education sect in any country. Two parallel frameworks run for higher education qualifications of UK degree-awarding bodies:</p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>The Framework for Higher Education Qualifications of Degree Awarding Bodies in England, Wales and Northern Ireland (FHEQ)</span></p>       
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>The Framework for Qualifications of Higher Education Institutions in Scotland (FQHEIS) Around 160 universities and colleges are allowed to award degrees in the UK.</span></p>       
            <h4 className='mt-4 pl-2'>Quality Assurance System</h4>
            <p className='text-muted'>The unique factor of education system in the UK is its Quality Assurance Agency (QAA). It is an independent body that assesses and compares universities and colleges in the country through regular audits and reviews. It also identifies the best education practices and provides feedback for areas of improvement. This ensures that the students have access to the best quality of education.</p>
            <p className='text-muted'>All review reports are public, and can be accessed on the QAA website. However, do note that private institutions are not subjected to assessment by QAA.</p>
            <h4 className='mt-4 pl-2'>Higher education</h4>
            <p className='text-muted'>Higher education in the UK mostly comprises bachelors, masters and doctoral degrees. As an international student, you may also transition to university level studies via pathway programs that generally include English language courses and foundation courses.</p>
            <h4 className='mt-4 pl-2'>Undergraduate courses</h4>
            <p className='text-muted'>Undergraduate courses are designed to help students get exposure to a whole new world after school whilst providing a thorough understanding of a subject of interest. The UK offers a variety of courses in humanities, social science, art, design, business, technology, science and more!</p>
          </div>
          <div className="div-3 d-none blog-block">
          <h4 className='mt-4 pl-2'>Indicative study costs for Australian programs</h4>
              <table className='table table-bordered mt-4'>
                <thead>
                  <tr>
                    <th>Program</th>
                    <th>Duration</th>
                  </tr>
                </thead>                    
                <tbody>
                  <tr>
                    <td>Higher National Certificate (HNC) or Diploma (HND)</td>
                    <td>1 to 2 years</td>
                  </tr>
                  <tr>
                    <td>Certificates or Diplomas of Higher Education (Cert HE/Dip HE)</td>
                    <td>1 to 2 years</td>
                  </tr>
                  <tr>
                    <td>Foundation degrees (Fd A/Fd Sc)</td>
                    <td>6 months to 2 years</td>
                  </tr>
                  <tr>
                    <td>Degrees (such as BA, B.Sc, BEng)</td>
                    <td>3 years in England, Wales and Northern Ireland</td>
                  </tr>
                  <tr>
                    <td>Sandwich degrees</td>
                    <td>4 years with a year in industry</td>
                  </tr>
                  <tr>
                    <td>Integrated courses (where masters is awarded as the first degree such as MEng)	</td>
                    <td>4 years Postgraduate studies</td>
                  </tr>                  
                </tbody>                    
              </table>  
              <h4 className='mt-4 pl-2'>Postgraduate studies</h4>
              <p className='text-muted'>Postgraduate courses are more specific and involve in-depth study. Most master’s degrees have duration of one year, which means you can be back in the workforce faster than your counterparts pursuing masters in other countries. There is a plethora of courses to choose from including niche courses that are highly valued in the industry.</p>      
              <p className='text-muted'>Here’s an overview of the types of postgraduate courses offered in the UK:</p>
              <table className='table table-bordered mt-4'>
                <thead>
                  <tr>
                    <th>Program</th>
                    <th>Duration</th>
                  </tr>
                </thead>                    
                <tbody>
                  <tr>
                    <td>Postgraduate certificates and diploma (PG Cert/PG Dip)	</td>
                    <td>9 to 12 months</td>
                  </tr>
                  <tr>
                    <td>Taught Masters (such as MA, M.Sc., LLM, MBA, MRes)	</td>
                    <td>Taught Masters (such as MA, M.Sc., LLM, MBA, MRes)	year</td>
                  </tr>
                  <tr>
                    <td>Research Masters (such as MPhil, PhD, DPhil)</td>
                    <td>2-3 years</td>
                  </tr>                             
                </tbody>                    
              </table> 
              <h4 className='mt-4 pl-2'>Pathway programs</h4>
              <p className='text-muted'>These are preparatory courses for seeking admission into universities through English Language learning programs or foundation-level courses. Pathway programs can help enter university-level studies in the country and help strengthen your language skills as well. These are offered by both universities and private institutions to help students who need extra assistance with English language to gain admission.</p>
              <h4 className='mt-4 pl-2'>Fee structure</h4>
              <p className='text-muted'>Your expenditure on tuition fee in the UK depends on two factors:</p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Where you choose to study – different rules and laws apply in England, Northern Ireland, Wales and Scotland</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>You study level – bachelors, masters or doctorate</span></p>
              <p className='text-muted'>Tuition fee for undergraduate students range from £10,000 a year to about £18,000 a year, depending on the course and institution. Fee for postgraduate courses vary based on the program, course duration and the course provider generally it range from £12,000 a year to about £24,000 a year.</p>
              <p className='text-muted'>Pedagogy in British classrooms</p>
              <p className='text-muted'>The UK has a high reputation for its education, which is mostly the reason why so many Indian students come here to pursue higher studies. The best part about studying in the UK is the way education is imparted – it goes beyond textbook learning. You’ll be engaged in various activities such as workshops, seminars, tutorials and conferences at all times.</p>
              <p className='text-muted'>Teachers will expect you to be interactive in the classroom which will involve regular participation in group discussions, quizzes and debates. You’ll also be required to learn to work independently. If you see, education in the UK will eventually enhance your communication skills and help you build critical thought-process, creativity and analytical skills. This may be a little challenging initially but you’ll get the hang of it with constant support from your teachers and classmates.</p>
              <h4 className='mt-4 pl-2'>Academic year</h4>
              <p className='text-muted'>The standard academic year in the UK begins around the month of September – October. The deadline for applications for courses commencing in September is generally in the preceding January of that year. Two other intakes available for programs in the UK are January and April, which are usually smaller than the September intake. Pathway programs may have additional dates to offer. Always check for the intakes available for your course of preference. You can also check about the upcoming intakes in the UK here.</p>
          </div>
          <div className="div-4 d-none blog-block" >
          <h4 className='mt-4 pl-2'>Popular programs</h4>
          <div className="steps-2 pl-3">
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>MBA</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Law</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Business management</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Computer Science</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Sports Management</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Health Care Management</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Science</span></p>
            <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Engineering</span></p>
          </div>
          {/* <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p>
          <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p>
          <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p>
          <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p>
          <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p>
          <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p>
          <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span></span></p> */}
          <h4 className='mt-4 pl-2'>Student visa requirements for the UK</h4>
          <p className='text-muted'>Applying for a UK student visa? Here’s help! The UK Government provides an opportunity to all Indian students to study in the country but as a prerequisite, you’ll have to obtain a student visa first. The visa you need will depend on your age and the type of study you wish to pursue in the UK. Here’s an overview* of the types of study visas and their application requirements:</p>
          <p className='text-muted'>Type of student visa Who it’s for</p>
          <p className='text-muted'>When to apply Arrival in the UK Tier 4 (General)
           <p className='text-muted'>student visa</p>
           <p className='text-muted'>Students who have been offered a place in a course at a UK educational institution with a valid Tier 4 sponsor</p>
           <p className='text-muted'>Three months before the start of your course</p>
           <p className='text-muted'>Up to one month before if your course lasts more than six months</p>
           <p className='text-muted'><strong>*For detailed information, please visit the <a href="Gov.UK">Gov.Uk</a> website</strong></p>
           <h4 className='mt-4 pl-2 mb-3'>To be eligible for a Tier 4 student visa, you must:</h4>
            <div className="steps-2 pl-3">
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Have an unconditional offer of a place on a course with a licensed Tier 4 sponsor</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Be able to speak, read, write and understand English</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Have enough money to support yourself and pay for your course.</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Documents required for Tier 4 student visa</span></p> 
            </div>
            <h4 className='mt-4 pl-2 mb-3'>When applying for your Tier 4 visa, you will generally need:</h4> 
            <div className="steps-2 pl-3">
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>A current passport or other valid travel documentation</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Evidence of funds to provide your living expenses for the duration of your course</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Confirmation of Acceptance for Studies (CAS) reference number and documents used to obtain CAS</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Passport-sized color photographs</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Tuberculosis screening (if required)</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Assessment documentation</span></p>
              <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Academic Technology Approval Scheme (ATAS) clearance certificate (if required)</span></p>
              <p className='text-muted'><strong>For more information don’t hesitate to contact our Experts at</strong></p>
            </div> 
          </p>
          </div>
          <div className='button-block text-left d-flex justify-content-between'>           
              <button className="button form-button mt-2 mb-4 common-shadow back d-none " id="back" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Back</button>
              <button className="button form-button mt-2 mb-4 common-shadow next float-right" id="next" role="button" data-id={counter} onClick={ToggleText}  style={{fontWeight:'700',color:"#fff"}} >Next</button>
         </div>  
        </div>
    </>
  )
}

export default Uk