import React from 'react';
import Carousel from 'react-multi-carousel';
import {Link, Outlet } from 'react-router-dom';


const Testimonial = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    const CustomerArr = [
        {
            name:"Ronne Galle",
            role:"Project Manager",
            image:"https://images.unsplash.com/photo-1572561300743-2dd367ed0c9a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=300&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=300",
            des:" Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat ",
        },
        {
            name:"Hanna Lisem",
            role:"Project Manager",
            image:"https://images.unsplash.com/photo-1549836938-d278c5d46d20?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=303&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=303",
            des:"Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat",
        },
        {
            name:"Martha Brown",
            role:"Project Manager",
            image:"https://images.unsplash.com/photo-1575377222312-dd1a63a51638?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=302&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=302",
            des:" Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat",
        },
        {
            name:"Missy Limana",
            role:"Engineer",
            image:"https://images.unsplash.com/photo-1588361035994-295e21daa761?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=301&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=301",
            des:"Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat",
        },
        
     
    ]
  return (
    <div>      
      <h2 className='text-center mt-5'><strong >Testimonials - Cards Comments</strong></h2>
      <ul className='row text-center m-auto py-5 col-md-5'>        
          <li className='col-4' style={{listStyle:"none"}}><Link to="/Testimonial/Testimonial-2"><button className='btn btn-primary'>Testimonial 2</button></Link></li>
          <li className='col-4' style={{listStyle:"none"}}><Link to="/Testimonial/Testimonial-3"><button className='btn btn-primary'>Testimonial 3</button></Link></li>            
      </ul>
      <div>
          <div className='col-10 m-auto'>
          <Carousel responsive={responsive}>    
              {CustomerArr.map((sliderImage,index)=>(                                                           
                  <Outlet context={sliderImage} />
              ))}
          </Carousel >    
          </div>            
      </div>
      </div>    
  )
}

export default Testimonial
