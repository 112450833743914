import React from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ScriptTag from "../ScriptTag";


const ContactUs = () =>{      
    const submitHandler = (e) =>{              
            e.preventDefault();
            const name = e.target.name.value;
            const email = e.target.email.value;
            const phone = e.target.phone.value;
            const message = e.target.message.value;
            
            axios.post('https://pgportals.com/api/add',{
                name,
                email,
                phone,
                message,
            }).then((res)=>{              
                if(res.data.status === 200){                    
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }else{
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            });        
    }

    return(
        <>
        <ToastContainer />
        <section className="ftco-section contact-us-block pb-5" id="contact-us-block">
            <div className="container">        
                {/* <div className="contact-us-heading-block text-center mb-5" data-aos="fade-up" data-aos-delay="300">
                    <h1 className="text-center heading-2 ">CONTACT US</h1>        
                    <h6 className="paragraph-4">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h6>
                </div> */}
                <div className="row justify-content-center mt-5">
                    <div className="col-md-12">                    
                        <div className="wrapper">
                            <div className="row no-gutters mb-5">
                                <div className="col-md-7" data-aos="fade-right" data-aos-delay="300">
                                    <div className="contact-wrap w-100 p-md-5 p-4 common-shadow bg-white">
                                        <h6 style={{textTransform:"uppercase",fontSize:"14px",fontWeight:"700",color:"#d20300"}}>why choose us</h6>
                                        <h2 className="mb-4" style={{fontWeight:"500"}}>Get In <span style={{fontWeight:"700"}}>Touch?</span></h2>
                                        <div id="form-message-warning" className="mb-4"></div>
                                        <div id="form-message-success" className="mb-4">
                                            Your message was sent, thank you!
                                        </div>
                                        <form method="POST" id="contactForm" name="contactForm" className="contactForm" onSubmit={submitHandler}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Group className="form-group">
                                                        <label className="label" htmlFor="name">Full Name</label>
                                                        <input type="text" className="form-control" name="name" id="name" placeholder="Name" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="form-group">
                                                        <label className="label" htmlFor="email">Email Address</label>
                                                        <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="form-group">
                                                        <label className="label" htmlFor="phone">Phone</label>
                                                        <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone" required/>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="form-group">
                                                        <label className="label" htmlFor="subject">Subject</label>
                                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
                                                    </Form.Group>
                                                </div>                                                
                                                <div className="col-md-12">
                                                    <Form.Group className="form-group">
                                                        <label className="label" htmlFor="#">Message</label>
                                                        <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder="Message" required></textarea>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <Form.Group className="form-group">
                                                        <input type="submit" value="Send Message" className="button common-shadow" style={{fontWeight:'700',background:'#d20300',color:'#fff',textTransform:"uppercase",padding:"17px 20px"}}/>
                                                        <div className="submitting"></div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-5 d-flex align-items-stretch " data-aos="zoom-in" data-aos-delay="500">
                                    <iframe width={900} className="common-shadow" src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.0937247919205!2d75.54245271512299!3d30.37669638176259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910f3f63e66e4d7%3A0xe0478bdc7d94c822!2sCANUS%20IMMIGRATION%20SERVICES!5e0!3m2!1sen!2sin!4v1676563373417!5m2!1sen!2sin"} style={{border:"none",minHeight:"650px !important"}}></iframe>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-md-4" data-aos="zoom-in">
                                    <div className="dbox w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <span className="fa fa-map-marker"></span>
                                        </div>
                                        <div className="text">
                                            <p><span>Address:</span> 1st Floor Shop No, 219, New Bus Stand Rd Barnala, Punjab 148101</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="zoom-in">
                                    <div className="dbox w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <span className="fa fa-phone"></span>
                                        </div>
                                        <div className="text">
                                            <p><span>Phone:</span> <a href="tel://+919417644786">+91 94176 44786</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="zoom-in">
                                    <div className="dbox w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <span className="fa fa-paper-plane"></span>
                                        </div>
                                        <div className="text">
                                            <p><span>Email:</span> <a href="mailto:canusimmigration786@gmail.com">canusimmigration786@gmail.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-4" data-aos="zoom-in">
                                    <div className="dbox w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <span className="fa fa-globe"></span>
                                        </div>
                                        <div className="text">
                                            <p><span>Website</span> <a href="#">yoursite.com</a></p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>       
            
        <ScriptTag name="contact-us"></ScriptTag>
        </>
    )
}

export default ContactUs;