import React , {useState} from 'react'
import $ from 'jquery';
import BlogForm from '../Form/BlogForm';


const Australia = () => {
    const total_div = $('.blog-block').length; 
    const [counter, setCounter] = useState(1); 
    
    const ToggleText = (e) =>{       
      if(e.target.id === "next"){                 
          setCounter(counter + 1);           
            // if(counter === (total_div - 1)){
            //   $('#next').attr("disabled", true);
            //   $('#next').css('cursor','no-drop');           
            // }            
            if(counter === 1){              
              $('.div-1').removeClass('d-block').addClass('d-none');        
              $('.div-2, #back , #next').removeClass('d-none').addClass('d-block');   
              $('.div-3').removeClass('d-block').addClass('d-none');                   
            }else if(counter === 2){            
              $('.div-1').removeClass('d-block').addClass('d-none');        
              $('.div-2,  #next').removeClass('d-block').addClass('d-none');        
              $('.div-3, #back').removeClass('d-none').addClass('d-block');                 
            }                                 
        }
        if(e.target.id === "back"){            
            setCounter(counter - 1);             
            if(counter === 2){              
              $('.div-1, #next').removeClass('d-none').addClass('d-block');   
              $('.div-2').removeClass('d-block').addClass('d-none');        
              $('.div-3, #back').removeClass('d-block').addClass('d-none');        
            }else if(counter === 3){
              $('.div-1').removeClass('d-block').addClass('d-none');        
              $('.div-2, #back , #next').removeClass('d-none').addClass('d-block');   
              $('.div-3').removeClass('d-block').addClass('d-none');       
            }
            $('#next').attr("disabled", false);
            $('#next').css('cursor','pointer');
           
        }        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; 
    }   
  return (
    <>
     
      <div className="col-md-8 mx-3 mx-md-0 mx-lg-0 common-shadow content-container">
          <div className="name-block" style={{borderLeft:'6px solid #ff0000'}}>
            <h1 className='pl-2 pb-0'>Australia</h1>            
          </div>
          <img src="/assets/australia.jpg" className="mt-3" alt="" />
          <div className="div-1 blog-block">
              <h4 className='mt-4 pl-2'>Australia Student Visa in 2022 Guide: Process, Requirements, Age Limit, and more!</h4>
              <p className='text-muted'> Standing proud with 8 of the top 100 universities in the world, Australia is much more than just kangaroos. An increasing number of Indian students are opting for Australia to study abroad for its world-className education, post-study work opportunities and excellent quality of living. Whatever course you choose to undertake, you'll be assured of unbeatable academic excellence and support system for international students.</p>
              <h4 className='mt-4 pl-2'>Benefits of Studying in Australia</h4>
              <div className="benifit-box ml-3 mt-4">
                  <div className="steps-2 pl-2">
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Comprehensive Practical Study Courses.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Thousands of Graduate and Undergraduate Programs.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Three Years Bachelor's Degree Acceptable.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Students are allowed to work for 20 hours per week.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Post Study Work Permit for up to 2 Years.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>NO GRE/GMAT for Post graduate studies Required.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Permanent Residence options after study.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Scholarship available for eligible Candidates.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Warm Welcoming Culture of Australia.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>High Visa Success rate.</span></p>
                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Multicultural Study and work Environment.</span></p>
                  </div>
                  <h5 className="pl-2 my-3">Here's an overview of the intakes available for different programs in universities and colleges:</h5>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Intake</th>
                        <th>Deadline</th>
                      </tr>
                    </thead>                    
                    <tbody>
                      <tr>
                        <td>Semester 1 (February intake)</td>
                        <td>October-November</td>
                      </tr>
                      <tr>
                        <td>Semester 2 (July intake)</td>
                        <td>April-May</td>
                      </tr>
                    </tbody>                    
                  </table>                  
              </div>
         </div>
         <div className="div-2 d-none blog-block" >
              <h4 className='mt-4 pl-2'>Indicative study costs for Australian programs</h4>
              <table className='table table-bordered mt-4'>
                <thead>
                  <tr>
                    <th>Institution/Degree</th>
                    <th>*Annual fees (indicative)</th>
                  </tr>
                </thead>                    
                <tbody>
                  <tr>
                    <td>School</td>
                    <td>$7,800 to $30,000</td>
                  </tr>
                  <tr>
                    <td>English language studies</td>
                    <td>$300/ week depending on course length</td>
                  </tr>
                  <tr>
                    <td>Vocational Education and Training (Certificates I to IV, Diploma and Advanced Diploma)</td>
                    <td>$10,000 to $22,000</td>
                  </tr>
                  <tr>
                    <td>Undergraduate Bachelor Degree	</td>
                    <td>Undergraduate Bachelor Degree	</td>
                  </tr>
                  <tr>
                    <td>Postgraduate Master's Degree</td>
                    <td>$20,000 to $37,000*</td>
                  </tr>
                </tbody>                    
              </table>         
              <h4 className="pl-2 my-3">Australian Qualifications Framework (AQF)</h4>
              <p className='text-muted'>The unique factor of the Australian education system is its implementation of Australian Qualifications Framework (AQF), the national policy established in 1995 that specifies the standards of qualifications for the tertiary education sector (higher education, vocational education and training) in addition to the school leaving certificate, called the Senior Secondary Certificate of Education. With AQF, your degree is recognized and authorized by the government.</p>
              <p className='text-muted'>Under the AQF, there are 10 levels that interconnect various schools, colleges, universities and educational institutions in one single national system. This allows flexibility; choice and better career planning for students to move from one study level to another as long as their student visa requirements are fulfilled.</p>
              <h4 className="pl-2 my-3">Study levels in Australia</h4>
              <p className='text-muted'>The education system in Australia is three-tiered with primary, secondary and tertiary levels of education. Here's an overview of the different levels and qualifications available as per the AQF.</p>
              <h6>Levels and qualifications as per Australian Qualification Framework (AQF)</h6>
              <div className="ml-3 mt-4">         
                  <table className='table table-bordered mt-4'>
                <thead>
                  <tr>
                    <th>Levels</th>
                    <th>Certifications</th>
                  </tr>
                </thead>                    
                <tbody>
                  <tr>
                    <td>Level 1</td>
                    <td>Certificate I</td>
                  </tr>
                  <tr>
                    <td>Level 2</td>
                    <td>Certificate II</td>
                  </tr>
                  <tr>
                    <td>Level 3</td>
                    <td>Certificate III</td>
                  </tr>
                  <tr>
                    <td>Level 4</td>
                    <td>Certificate IV</td>
                  </tr>
                  <tr>
                    <td>Level 5</td>
                    <td>Diploma</td>
                  </tr>
                  <tr>
                    <td>Level 6</td>
                    <td>Advanced Diploma, , Associate Degree</td>
                  </tr>
                  <tr>
                    <td>Level 7</td>
                    <td>Bachelor Degree</td>
                  </tr>
                  <tr>
                    <td>Level 8</td>
                    <td>Bachelor Honors' Degree, Graduate Certificate, Graduate Diploma</td>
                  </tr>
                  <tr>
                    <td>Level 9</td>
                    <td>Master's Degree</td>
                  </tr>
                  <tr>
                    <td>Level 10</td>
                    <td>Doctoral Degree</td>
                  </tr> 
                  <tr>
                    <td colSpan={2}><strong>Source</strong>: <a href="https://www.aqf.edu.au/aqf-levels"  target="_blank" >Click Here</a></td>
                  </tr>                
                </tbody>                    
              </table>
              </div>
              </div>
              <div className="div-3 d-none blog-block" >
              <h4 className='mt-4 pl-2'>Applying for an Australian student visa? Here's help!</h4>
              <p className='text-muted'>The Australian Government provides an opportunity to all Indian students to study in the country but as a prerequisite, you’ll have to obtain a student visa first. The visa you need will depend on your age, the type of study you want to pursue, and how long you want to stay in Australia. As an international student, you would most commonly require a student (subclass 500) visa, regardless of your field of study.</p>
              <p className='text-muted'>Student visa (subclass 500)
                Coming into effect since July 1, 2016, a student subclass 500 visa lets you stay in Australia for the duration of your study program, which is mostly up to five years. It is important for you to ensure that your visa remains valid and you abide by the conditions as mentioned on your student visa. You can read up in detail about this kind of visa on the Australian Government’s Department of Home Affairs website.
                Documents required for an Australian student visa</p>
              <p className='text-muted'>When applying for your student visa for Australia, you will generally need:</p>
              <div className="steps-2 pl-2">
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Proof of enrollment (your electronic confirmation of enrollment)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>A valid passport</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Your visa application fee</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Your Genuine Temporary Entrant (GTE) statement</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Academic and work experience documents</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Evidence of your English proficiency skills (book your place in the IELTS test)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Evidence of financial ability (tuition fees, living expenses, expenses for dependents, return airfare)</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Details of your health insurance policy (Overseas Student Health Cover )</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Statement of purpose</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>Passport sized photographs</span></p>
                <p><i className="fa fa-check-circle" aria-hidden="true"></i> <span>If you are under 18 years of age, you will also need to provide additional documents such as parental consent.</span></p>
              </div>
              <p className="text-muted"><strong>For more information don’t hesitate to contact our Experts at</strong></p>              
         </div>
         <div className='button-block text-left ml-3 d-flex justify-content-between'>           
              <button className="button form-button mt-2 mb-4 common-shadow back d-none " id="back" role="button" onClick={ToggleText} style={{fontWeight:'700',color:"#fff"}} >Back</button>
              <button className="button form-button mt-2 mb-4 common-shadow next float-right" id="next" role="button" data-id={counter} onClick={ToggleText}  style={{fontWeight:'700',color:"#fff"}} >Next</button>
         </div>           
      </div>
    </>
  )
}

export default Australia