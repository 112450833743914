import React from 'react';
import {Link} from 'react-router-dom'

function Feature() {
    const data = [
        {
            country:"Canada",
            image:"/assets/canada.jpg",
            flag:"/assets/flags/ca.svg",
        },
        {
            country:"Germany",
            image:"/assets/germany.jpg",
            flag:"/assets/flags/ge.svg",
        },
        {
            country:"Australia",
            image:"/assets/australia.jpg",
            flag:"/assets/flags/au.svg",
        },
        {
            country:"United Kingdom",
            image:"/assets/uk.jpg",
            flag:"/assets/flags/uk.svg",
        },       
        {
            country:"New Zealand",
            image:"/assets/new_zealand.jpg",
            flag:"/assets/flags/nz.svg",
        },
        {
            country:"United State",
            image:"/assets/usa.jpg",
            flag:"/assets/flags/us.svg",
        }
    ]
  return (
    <div className="feature_block">
        <div className="section-2 wf-section" style={{overflow:"hidden"}} data-aos="fade-up" data-aos-delay="100" >
            <h1 className="heading-6" style={{color:'#001b69',fontWeight:'bold',textAlign:'center'}}>GLOBAL STUDY DESTINATIONS</h1>
            <h6 className="paragraph-4 text-center mx-3">Exploring the Best Global Study Destinations</h6>
            {/* <div classNameName="container-9 w-container"><a href="#" classNameName="button-6 w-button">VIEW ALL</a></div> */}
        </div>
    
        <div className="container">
            <div className="feature_cards row" data-aos="fade-up" data-aos-delay="100">
                {data.map((coun,index)=>(               
                    <div className="col-lg-4 mb-4" key={index}>
                    <div className="card feature-card">                       
                         <div className="card-image" style={{backgroundImage:"url('"+ coun.image +"')"}}></div>
                        <div className="country-flag">
                            <div className="small-flag flag-uk" style={{backgroundImage:"url('"+ coun.flag +"')"}} ></div>
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title">{coun.country}</h5>
                            <p className="card-text country-desc">Millions of decisions about who has the right to visit or stay.</p>
                            <strong className='center-flex-items'><Link to={'Blog/' + coun.country.replace(" ","-").toLowerCase()}>Read More </Link></strong>
                        </div>
                    </div>
                </div>
                ))}           
                <div className="container-4 w-container"><Link to="Blog/canada" className="button common-shadow" style={{padding:"17px 30px"}}>VIEW ALL</Link></div>     
            </div>
        </div>    

    </div>
  )
}

export default Feature