import React from 'react';

const ScriptTag = (props) => {
 var contactUsArr = [    
        'js/jquery.min.js',
        'js/popper.js',
        'js/popper.js',
        'js/jquery.validate.min.js',
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false',
        'js/google-map.js',
        'js/main.js'
 ];
 
 const RenderScript = (name) =>{
         switch(name){
            case 'contact-us':
                return contactUsArr;
            default:
                return console.log('no-script');
         }
 }
  return (
    <>                                   
        { RenderScript(props.name).map((tagLink,index)=>
            <script src={tagLink} key={index}></script>
            )
        }
    </>          
  )
}

export default ScriptTag
