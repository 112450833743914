import {React, useEffect} from 'react';
import { Link ,useLocation } from 'react-router-dom';
// import logo from './logo.png';
import $ from 'jquery';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function MainHeader() {
    const location = useLocation();
    const curret_active = location.pathname.split("/")[1];         
    var service_btn = (curret_active === "" && curret_active !== "undefined" ) ? <li><AnchorLink href="#service_block" className="nav-link">Services</AnchorLink></li> : '';
    useEffect(() => {
        $("#home-btn").addClass('active');
    }, [])

    $(document).on('click','.nav-link',function(e){
        e.preventDefault();
        $('.nav-link').removeClass('active');                
        $(this).addClass('active');
    })
    $(document).on('click','.contact-us-button',function(e){
        $('.nav-link').removeClass('active');
    });
    
  return (
    <div>
        <div className="navbar-logo-left wf-section common-shadow" >
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-logo-left-container w-nav">
            <div className="container">
                <div className="navbar-wrapper" style={{position:'sticky'}}>
                    <Link to="/" className="w-inline-block"><img src="/assets/favicon2.png" loading="lazy" width="65" alt="" className="image-19" /></Link>
                    <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                        <ul className="nav-menu-two w-list-unstyled" style={{textTransform:"uppercase",fontWeight:"700"}}>
                            <li><Link to="/" className="nav-link" id="home-btn">Home</Link></li>
                            {service_btn}
                            
                            
                            {/* <li><Link to="/Testimonial" className="nav-link">Testimonials</Link></li>
                            <li><Link to="/Video" className="nav-link">Videos</Link></li> */}
                            {/* <li> */}
                                {/* <div data-hover="false" data-delay="0" className="nav-dropdown w-dropdown">
                                    <div className="nav-dropdown-toggle w-dropdown-toggle">
                                        <div className="nav-dropdown-icon w-icon-dropdown-toggle"></div>
                                        <div className="text-block-3">Resources</div>
                                    </div>
                                    <nav className="nav-dropdown-list shadow-three mobile-shadow-hide w-dropdown-list">
                                        <a href="#" className="nav-dropdown-link w-dropdown-link">Resource Link 1</a><a href="#" className="nav-dropdown-link w-dropdown-link">Resource Link 2</a>
                                        <a href="#" className="nav-dropdown-link w-dropdown-link">Resource Link 3</a>
                                    </nav>
                                </div> */}
                                <li><Link to="/Blog/canada" className="nav-link" id="study-abroad-btn">Study Abroad</Link></li>
                            {/* </li> */}
                            {/* <li><Link to="/ielts-exam-dates" className="nav-link">IELTS Test Dates</Link></li> */}
                            <li>
                                <div className="nav-divider"></div>
                            </li>
                            <li><Link to="/About-us" className="nav-link-accent about-us-btn nav-link" id="about-us-btn">About</Link></li>
                            <li className="mobile-margin-top-10"><Link to="/Contact-us" className="button contact-us-button common-shadow" style={{fontWeight:'700',padding:'15px 20px',background:'#d20300',color:'#fff',letterSpacing:"1px"}}>Contact us</Link></li>
                        </ul>
                    </nav>
                    <div className="menu-button w-nav-button">
                        <div className="w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default MainHeader